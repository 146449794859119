import {graphql, useStaticQuery} from 'gatsby';

export const useCareer = () => {
  const {careersJson} = useStaticQuery(graphql`
    query Careers {
      careersJson {
        intro
        description
        hire {
          title
          description
          contact
        }
        pics {
          event
          eventImage
        }
        carousel {
          description
          title
        }
        content {
          title
          description
          icon
          alt
        }
      }
    }
  `);

  return careersJson;
};
