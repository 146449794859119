import React from 'react';

// Bootstrap
import {Container} from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// Less
import './Expectations.less';
import { createAssetUrl } from '../../../utils/helper';

const Expectations = ({description, content}) => {
  const expectations = content.map((expectation, index) => (
    <Col className="expectations__item" xs={12} sm={12} md={4} key={index}>
      <div className="expectations__item-title-container">
        <img src={createAssetUrl(expectation.icon)} alt={expectation.alt} className="carrer-icons"/>
        <h3 className="expectations__item-title">{expectation.title}</h3>
      </div>
      <p className="expectations__item-description">
        {expectation.description}
      </p>
    </Col>
  ));

  return (
    <div className="expectations">
      <Container>
        <h3 className="expectations__description">{description}</h3>
        <Row className="expectations__items">{expectations}</Row>
      </Container>
    </div>
  );
};

export default Expectations;
