import React from 'react';

// Layout
import Layout from '../containers/layout/Layout';

// Components
import Banner from '../components/common/banner/Banner';
import Opportunities from '../components/careers/opportunities/Opportunities';
import Expectations from '../components/careers/expectations/Expectations';

// Assets
import bannerImage from '../../static/assets/banner/banner-careers-ro.png';

// GraphQL
import {useCareer} from '../graphql/career/career';
import {useAvailableJobs} from '../graphql/career/availableJobs';

const Careers = () => {
  const {intro, description, hire, content} = useCareer();
  const availableJobsData = useAvailableJobs();

  const bannerStyle = {
    opacity: {
      opacity: '0.75',
    },
    overlay: {
      backgroundImage: `url(${bannerImage})`,
    },
  };

  return (
    <Layout>
      <Banner
        bannerOpacity={bannerStyle.opacity}
        bannerOverlay={bannerStyle.overlay}
        className="bannerSecondary"
      >
        GROW WITH ENSEMBLE // CAREERS
      </Banner>
      {availableJobsData && availableJobsData.length > 0 && (
        <Opportunities
          intro={intro}
          hire={hire}
          availableJobs={availableJobsData}
        />
      )}
      <Expectations description={description} content={content} />
    </Layout>
  );
};

export default Careers;
