import React from 'react';
import {Link} from 'gatsby';
import TypewriterComponent from 'typewriter-effect';

// Bootstrap
import {Container} from 'react-bootstrap';

// Less
import './Opportunuties.less';

const Opportunities = ({
  intro,
  hire: {title: hireTitle, description, contact},
  availableJobs,
}) => {
  const availableJobsLinks = availableJobs.map(({node}, key) => (
    <Link
      to={`/careers/${node.slug}`}
      className="opportunities__link"
      key={key}
    >
      <button type="button" className="opportunities__button">
        {node.position}
      </button>
    </Link>
  ));

  return (
    <div className="opportunities">
      <Container>
        <h2 className="opportunities__title flex-wrap">
          {intro}
          &nbsp;
          <TypewriterComponent
            onInit={() => {}}
            options={{
              strings: ['SOFTWARE', 'APPS', 'WEBSITES'],
              autoStart: true,
              loop: true,
              skipAddStyles: true,
            }}
          />
        </h2>
        <p className="opportunities__description">
          Our mantra is simple:&nbsp;
          <i>IT made casual.</i>
        </p>
        <p style={{marginBottom: 0}}>
          We&apos;re lucky to have a great team of incredible people, each of
          them responsible of making every project a success.
        </p>
        <p>
          We&apos;re proud of what we do and we&apos;re committed to giving
          everyone the best tools to do their tasks and do it well.
        </p>
        <p className="opportunities__description">
          <b>No one can recommend us better than our friends.</b>
        </p>
        <p>
          So we asked them to tell you a little bit about Ensemble. Here it
          goes:
        </p>
        <div className="mx-auto mb-0 pb-0 w-75 my-5">
          <div
            id="wistiaHeroVideo"
            className="wistia_embed wistia_async_9sd14w81s1"
          />
        </div>
        <p>
          Like they said, we&apos;re looking to expand our team. And our friends
          list.
        </p>
        <h2 className="opportunities__title--bigger">
          Current Opportunities in Baia Mare or Remote:
        </h2>
        <div className="opportunities__links-container">
          {availableJobsLinks}
        </div>
        <div className="opportunities__hire">
          <p>{hireTitle}</p>
          <p>
            {description}
            <a href={`mailto:${contact}`}>{contact}</a>
          </p>
        </div>
      </Container>
    </div>
  );
};

export default Opportunities;
