import {graphql, useStaticQuery} from 'gatsby';

export const useAvailableJobs = () => {
  const {allAvailableJobsJson} = useStaticQuery(graphql`
    query AvailableJobs {
      allAvailableJobsJson {
        edges {
          node {
            position
            slug
          }
        }
      }
    }
  `);

  return allAvailableJobsJson.edges;
};
